
import { defineComponent, nextTick, onMounted, ref } from "vue";

import { StickyComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Order } from "@/core/model/Orders";
import { OrderDetail } from "@/core/model/OrderDetail";
import moment from "moment";
import Swal from "sweetalert2";
import ToastService from "@/core/services/ToastService";
import { displayErrors } from "@/core/helpers/errors";
import UtilsService from "@/core/services/UtilsService";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

interface SummaryMethodProps<T = OrderDetail> {
  columns: [];
  data: T[];
}

export default defineComponent({
  name: "detalle-solicitud-credito",
  components: {},
  setup() {
    const util = UtilsService;
    const loading = ref<boolean>(false);
    const store = useStore();
    const route = useRoute();
    const order = ref<Order>({} as Order);
    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref({
      state_order: "",
      order: "",
    });
    onMounted(() => {
      setCurrentPageActions(false, false, []);
      setCurrentPageBreadcrumbs("Solicitudes de crédito", [
        "Órdenes",
        "Detalle",
      ]);
      loadData();
      nextTick(() => {
        StickyComponent.reInitialization();
      });
    });

    //Form submit function
    const onSubmit = async () => {
      //await store.UpdatedTracking(formData.value);
      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de actualizar la orden?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {
          formData.value.order = order.value.uuid;
          const params = {
            order: formData.value.order,
            state_order: formData.value.state_order,
          };
          store
            .dispatch(Actions.ORDER_UPDATE_STATE, params)
            .then(() => {
              formData.value.state_order = "";
              loadData();
            })
            .catch(() => {
              displayErrors(store, "OrdersModule");
            });
        } else {
          console.log("No se ejecutó la API correctamenterr");
        }
      });
    };

    const loadData = () => {
      loading.value = true;
      store
        .dispatch(Actions.ORDER_GET, route.params.uuid)
        .then(() => {
          order.value = store.state.OrdersModule.order;
          order.value.items.forEach((item) => {
            item.product_subtotal = item.product_price * item.product_quantity;
          });
        })
        .catch(() => {
          displayErrors(store, "OrdersModule");
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const formatDateTime = (value) => {
      return value === undefined
        ? ""
        : moment(value).format("DD/MM/YYYY hh:mm a");
    };

    const getSummaryOrder = (param: SummaryMethodProps) => {
      const { columns, data } = param;
      const sums: string[] = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "TOTAL";
          return;
        }
        if (data.length > 0) {
          let total = 0;
          const values = data.map((item) => Number(item[column["property"]]));
          if (!values.every((value) => Number.isNaN(value))) {
            total = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!Number.isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            if (index > 1) {
              sums[index] = util.formatAmount(total);
            } else {
              sums[index] = total.toString();
            }
          } else {
            sums[index] = "N/A";
          }
        }
      });

      return sums;
    };

    return {
      order,
      formatDateTime,
      formData,
      formRef,
      onSubmit,
      loading,
      util,
      getSummaryOrder,
      store,
    };
  },
});
